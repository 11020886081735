<template>
  <div>
    <app-card>
      <template #body>
        <certificate-form @change="onFormChange"/>
      </template>
    </app-card>

    <portal to="subheader-toolbar">
      <b-button class="mr-3" variant="transparent-white" @click="$router.push({name: 'showBlock', params: {id: blockId}, hash: '#elevators'})">
        {{ $t('btn.back_to_block') }}
      </b-button>
      <b-button variant="success" :disabled="!validation" @click="create">
        {{ $t('btn.create') }}
      </b-button>
    </portal>
  </div>
</template>

<script>
import AppCard from '@/components/AppCard';
import CertificateForm from './components/CertificateCreateForm';

export default {
  components: {
    AppCard,
    CertificateForm
  },
  data: () => ({
    blockId: null,
    form: {},
    validation: false
  }),
  beforeMount() {
    this.blockId = parseInt(this.$route.query.block_id);
  },
  methods: {
    onFormChange(e) {
      this.form = e.form;
      this.validation = e.validation;
    },
    create() {
      if (!this.validation) return false;
      this.$store.dispatch('elevatorCertificatesStore/CREATE', this.form).then(() => {
        this.$router.push({name: 'showBlock', params: {id: this.blockId}, hash: '#elevators'});
      });
    }
  }
}
</script>